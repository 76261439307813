import Search2021 from "../../Assets/svg/Search_2021_icon";

import { ApplicationShellWrapper } from "@escid-esmkt/applicationshellwrapper";
import * as debounceSsw from "lodash/debounce";
import * as React from "react";
import * as ReactModal from "react-modal";
import * as SearchBoxTypes from "../../types/searchbox";
import * as LocaHelper from "../../util/localizationHelper";

import Modal from "../modal/modal";
import Suggestions from "../suggestions/suggestions";
import SuggestionsWithImagesAndPrices from "../suggestions/suggestionsWithImagesAndPrices";

import "./searchbox.scss";
import Search from "../../util/search";

export default class SearchBox extends Search<
    SearchBoxTypes.ISearchBoxProps,
    SearchBoxTypes.ISearchBoxStates
> {
    private abortController: AbortController | undefined;
    private appShellWrapper: ApplicationShellWrapper;

    constructor(props: SearchBoxTypes.ISearchBoxProps) {
        super(props);

        this.state = {
            focus: false,
            searchTerm: "",
            showModal: false,
            suggestions: [],
            articles: [],
            contents: [],
            featurePromotion: "",
            topSearchQueries: [],
            useNewSuggestions: false,
            customerType: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.activateFocus = this.activateFocus.bind(this);
        this.deactivateFocus = this.deactivateFocus.bind(this);

        this.appShellWrapper = ApplicationShellWrapper.getInstance();
    }

    public componentDidMount(): void {
        this.appShellWrapper.subscribeTo(
            "ESMKT.HeadR.FlyoutClosing",
            (payload) => {
                this.clearSearchTermForFlyout(payload);
            },
            "ESMKT.HeadR.FlyoutClosing"
        );

        this.setState({
            searchTerm: this.inputRef.current.value || "",
            customerType: this.getCostumerType()
        });

        this.inputRef.current.blur();
        document.addEventListener("mousedown", this.handleClick, false);

        const flyoutIcon = document.querySelector(".scope-ssw.mkt-headr-icon-wrapper");
        flyoutIcon?.addEventListener("click", () => {
            if (this.searchBoxRef.current.classList.contains("flyout"))
                if (this.state.focus) {
                    this.inputRef.current.blur();
                    setTimeout(() => {
                        this.setState({ focus: false });
                    }, 200);
                } else {
                    this.inputRef.current.focus();
                    setTimeout(() => {
                        this.setState({ focus: true });
                    }, 200);
                }
        });
    }

    public componentDidUpdate(): void {
        this.bindKeyboardNavigation();
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    public render() {
        ReactModal.setAppElement("body");
        const { focus } = this.state;
        const dontShowSuggestions = !this.areSuggestionsInResponse() || !this.state.searchTerm;
        const priceL10n = LocaHelper.decodePriceL10n(this.props.l10n.prices);
        const productFinderL10n = LocaHelper.decodeProductFinderL10n(this.props.l10n.finders);
        const variantsL10n = LocaHelper.decodeVariantsL10n(this.props.l10n.variants);

        return (
            <div
                className={
                    "searchbox" +
                    ` ${this.props.view}` +
                    ` ${focus ? "focus" : ""}` +
                    ` ${this.props.tenant}` +
                    ` ${this.state.useNewSuggestions ? "" : "legacy"}`
                }
                ref={this.searchBoxRef}
            >
                <div
                    className={
                        this.hasMultipleSuggestionListsForLegacy() ? "search large" : "search"
                    }
                >
                    <form
                        method="get"
                        action={this.props.l10n.searchTarget}
                        acceptCharset="utf-8"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            ref={this.inputRef}
                            className="ssw-input"
                            type="text"
                            name="query"
                            aria-label={this.decodeHTML(this.props.l10n.defaultTerm)}
                            placeholder={
                                focus || this.state.searchTerm
                                    ? ""
                                    : this.decodeHTML(this.props.l10n.defaultTerm)
                            }
                            maxLength={50}
                            value={focus || this.state.searchTerm ? this.state.searchTerm : ""}
                            onChange={this.handleChange}
                            onFocus={this.activateFocus}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="none"
                            spellCheck="false"
                        />
                        <button
                            className="ssw-button"
                            type="submit"
                            aria-label={this.decodeHTML(this.props.l10n.defaultTerm)}
                        >
                            <Search2021 />
                        </button>
                    </form>
                </div>
                {!dontShowSuggestions && (
                    <>
                        <div className={"ssw-backdrop"} onClick={this.deactivateFocus} />
                        {this.state.useNewSuggestions ? (
                            <div
                                id={`new-suggestions-${this.props.view}`}
                                className={"new-suggestions"}
                            >
                                <SuggestionsWithImagesAndPrices
                                    trackingEndpoint={this.props.trackingEndpoint}
                                    portal={this.props.portal}
                                    culture={this.props.culture}
                                    ref={this.suggestionsWithImagesAndPricesRef}
                                    isWide={this.props.view === "wide"}
                                    view={this.props.view}
                                    searchTerm={this.state.searchTerm}
                                    articles={this.state.articles}
                                    contents={this.state.contents}
                                    featurePromotion={this.state.featurePromotion}
                                    topSearchQueries={this.state.topSearchQueries}
                                    priceMandantSettings={this.state.priceMandantSettings}
                                    customerType={this.state.customerType}
                                    priceL10n={priceL10n}
                                    seeAllResults={this.decodeHTML(this.props.l10n.seeAllResults)}
                                    imageMainView={this.decodeHTML(this.props.l10n.imageMainView)}
                                    articlesSuggestHeadline={this.decodeHTML(
                                        this.props.l10n.articlesSuggestHeadline
                                    )}
                                    contentSuggestHeadline={this.decodeHTML(
                                        this.props.l10n.contentSuggestHeadline
                                    )}
                                    topSearchQueriesHeadline={this.decodeHTML(
                                        this.props.l10n.topSearchQueriesHeadline
                                    )}
                                    productFinderL10n={productFinderL10n}
                                    variantsL10n={variantsL10n}
                                    searchTarget={this.props.l10n.searchTarget}
                                />
                            </div>
                        ) : (
                            <div
                                className={
                                    this.hasMultipleSuggestionListsForLegacy()
                                        ? "suggestions multiple-lists"
                                        : "suggestions"
                                }
                            >
                                <Suggestions
                                    trackingEndpoint={this.props.trackingEndpoint}
                                    portal={this.props.portal}
                                    culture={this.props.culture}
                                    ref={this.suggestionRef}
                                    suggestions={this.state.suggestions}
                                    searchTerm={this.state.searchTerm}
                                    showMultipleSuggestionLists={this.hasMultipleSuggestionListsForLegacy()}
                                    articlesSuggestHeadline={this.decodeHTML(
                                        this.props.l10n.articlesSuggestHeadline
                                    )}
                                    contentSuggestHeadline={this.decodeHTML(
                                        this.props.l10n.contentSuggestHeadline
                                    )}
                                    featurePromotion={this.state.featurePromotion}
                                    topSearchQueries={this.state.topSearchQueries}
                                    topSearchQueriesHeadline={this.decodeHTML(
                                        this.props.l10n.topSearchQueriesHeadline
                                    )}
                                    productFinderL10n={productFinderL10n}
                                    searchTarget={this.props.l10n.searchTarget}
                                />
                            </div>
                        )}
                    </>
                )}
                {this.props.view === "wide" && (
                    <Modal
                        open={this.state.showModal}
                        tenant={this.props.tenant}
                        closeModal={this.hideModal}
                        l10n={this.props.l10n}
                        featureToggles={this.props.featureToggles}
                    />
                )}
            </div>
        );
    }

    private getCostumerType(): string {
        const customerTypeValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("CustomerType="))
            ?.split("=")[1];
        // use Private as default value
        return customerTypeValue ? customerTypeValue : "Private";
    }

    private clearSearchTermForFlyout(flyoutName: string): void {
        if (flyoutName === "SearchBoxFlyout") {
            this.setState({ searchTerm: "" });
        }
    }

    private hideModal = () => {
        this.setState({ showModal: false });
    };

    private decodeHTML(value: string): string {
        return value.replace(/&#(\d+);/g, (_match, dec) => {
            return String.fromCharCode(dec);
        });
    }

    private handleChange(event): void {
        if (!event || !event.target) {
            this.resetSuggestionState();
            return;
        }

        const validSearchTerm =
            event.target.value.length > 50 ? event.target.value.slice(0, 50) : event.target.value;
        this.setState({ searchTerm: validSearchTerm });
        event.target.value ? this.debouncedFetchSuggestions(event) : this.resetSuggestionState();
    }

    private debouncedFetchSuggestions(event): void {
        event.persist();

        if (!this.debouncedFetchSuggestionsFunc)
            this.debouncedFetchSuggestionsFunc = debounceSsw(() => {
                this.fetchSuggestion(event);
            }, 250);

        this.debouncedFetchSuggestionsFunc();
    }

    private fetchSuggestion(event): void {
        if (!event || !event.target || !event.target.value || !event.target.value.trim().length) {
            this.resetSuggestionState();
            return;
        }

        const searchTerm: string = event.target.value;
        if (searchTerm.length > 50) return;

        const topSearchQueriesLimit: number = this.isDesktop ? 10 : 5;

        const headers = this.pageViewLogDataId.get()
            ? { "X-PageView-ID": this.pageViewLogDataId.get() }
            : {};

        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        const signal = this.abortController.signal;

        fetch(
            `${this.getSuggestionUrl()}?searchTerm=${encodeURIComponent(
                searchTerm
            )}&topSearchQueriesLimit=${topSearchQueriesLimit}`,
            { headers, signal }
        )
            .then((response) => response.json())
            .then(
                (response) => {
                    if (response) {
                        // check for legacy or new suggestion type
                        if (response.clientSuggestionsList) {
                            // legacy suggestion type
                            response.clientSuggestionsList.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: false,
                                      suggestions: response.clientSuggestionsList,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries
                                  })
                                : this.resetSuggestionState();
                        } else {
                            // new suggestion type
                            response.articles.length ||
                            response.contents.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: true,
                                      articles: response.articles,
                                      contents: response.contents,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries,
                                      priceMandantSettings: response.priceMandantSettings
                                  })
                                : this.resetSuggestionState();
                        }
                    } else this.resetSuggestionState();
                },
                () => {
                    this.resetSuggestionState();
                }
            )
            .then(() => (this.abortController = undefined));
    }

    private areSuggestionsInResponse(): boolean {
        if (this.state.useNewSuggestions) {
            return (
                this.state.articles.length > 0 ||
                this.state.contents.length > 0 ||
                this.state.topSearchQueries.length > 0
            );
        } else {
            return (
                this.state.suggestions.some((list) => list.clientSuggestion.length > 0) ||
                this.state.topSearchQueries.length > 0
            );
        }
    }

    private hasMultipleSuggestionListsForLegacy(): boolean {
        if (this.state.useNewSuggestions) return false;
        return (
            this.state.suggestions.some((list) => list.clientSuggestion.length > 0) &&
            this.state.topSearchQueries.length > 0
        );
    }

    private resetSuggestionState(): void {
        this.setState((state: SearchBoxTypes.ISearchBoxStates) => ({
            ...state,
            suggestions: [],
            featurePromotion: "",
            topSearchQueries: [],
            articles: [],
            contents: [],
            priceMandantSettings: {}
        }));
    }

    private handleSubmit(event): void {
        event.preventDefault();
        if (this.state.searchTerm === "") {
            if (!this.searchBoxRef.current.classList.contains("flyout"))
                this.setState({ showModal: true, focus: false });
            this.inputRef.current.blur();
        } else
            window.location.href = `${this.props.l10n.searchTarget}?query=${encodeURIComponent(
                this.state.searchTerm
            )}`;
    }

    private handleClick(event) {
        if (!this.searchBoxRef.current.contains(event.target)) this.deactivateFocus();
    }

    private activateFocus(event): void {
        let opaqueheaderdelay = 0;

        this.appShellWrapper.publishTo("SSW.expand", {});
        // tell HeadR that should show small logo
        this.appShellWrapper.publishTo("ESMKT.HeadR.ForceSmallLogo", true);

        if (!event.target.closest(".flyout")) {
            this.appShellWrapper.publishTo("ESMKT.HeadR.CloseFlyout", null);
            this.appShellWrapper.publishTo("ESMKT.HeadR.ForceOpaqueHeader", true);
            opaqueheaderdelay = 200;
        }

        setTimeout(() => {
            this.setState((state: SearchBoxTypes.ISearchBoxStates) => ({
                ...state,
                focus: true
            }));
        }, opaqueheaderdelay);

        if (this.state.useNewSuggestions) {
            if (
                this.state.articles.length < 1 &&
                this.state.contents.length < 1 &&
                this.state.searchTerm
            ) {
                this.fetchSuggestion(this.state.searchTerm);
            }
        } else {
            if (this.state.suggestions.length < 1 && this.state.searchTerm) {
                this.fetchSuggestion(this.state.searchTerm);
            }
        }
    }

    private deactivateFocus(): void {
        this.appShellWrapper.publishTo("SSW.collapse", {});
        // tell HeadR that no need to force show small logo anymore
        this.appShellWrapper.publishTo("ESMKT.HeadR.ForceSmallLogo", false);

        setTimeout(() => {
            this.setState((state: SearchBoxTypes.ISearchBoxStates) => ({
                ...state,
                focus: false
            }));
            if (this.searchBoxRef.current.classList.contains("wide")) {
                this.inputRef.current.blur();
                this.appShellWrapper.publishTo("ESMKT.HeadR.ForceOpaqueHeader", false);
            }
        }, 200);
    }

    private getSuggestionUrl(): string {
        const culturePrefix: string = "/" + this.props.culture.split("-")[0];
        return culturePrefix + "/api/shopsearch-web/suggestion/";
    }

    private bindKeyboardNavigation(): void {
        if (!this.isDesktop) return;

        this.state.focus
            ? document.addEventListener("keydown", this.handleKeyPressed)
            : document.removeEventListener("keydown", this.handleKeyPressed);
    }

    private handleKeyPressed(event: KeyboardEvent): void {
        if (this.state.useNewSuggestions) {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionsWithImagesAndPricesRef.current != null)
                        this.suggestionsWithImagesAndPricesRef.current.selectNextSuggestionEntry(
                            false
                        );
                    break;
                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionsWithImagesAndPricesRef.current != null)
                        this.suggestionsWithImagesAndPricesRef.current.selectNextSuggestionEntry(
                            true
                        );
                    break;
                case "Enter":
                    if (this.suggestionsWithImagesAndPricesRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionsWithImagesAndPricesRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;
                case "Esc":
                case "Escape":
                    this.deactivateFocus();
                    break;
                default:
                    return;
            }
        } else {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(false);
                    break;
                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(true);
                    break;
                case "Enter":
                    if (this.suggestionRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;
                case "Esc":
                case "Escape":
                    if (this.suggestionRef.current != null) this.deactivateFocus();
                    break;
                default:
                    return;
            }
        }
    }
}
