import { IMinimalPriceContainer, IPriceL10n, IPriceMandantSettings } from "./article-price";
import { IPromotion } from "./promotion";
import { IProductFinderL10n, IVariantsL10n } from "./searchbox";
import { ITopSearchQuery } from "./topsearchquery";

export interface ISuggestionsProps {
    trackingEndpoint: string;
    portal: string;
    culture: string;
    suggestions: ISuggestions[];
    featurePromotion: string;
    topSearchQueries: string[];
    searchTerm: string;
    showMultipleSuggestionLists: boolean;
    articlesSuggestHeadline: string;
    contentSuggestHeadline: string;
    topSearchQueriesHeadline: string;
    productFinderL10n: IProductFinderL10n;
    searchTarget?: string;
}

export interface ISuggestionsWithImagesAndPricesProps {
    trackingEndpoint: string;
    portal: string;
    culture: string;
    isWide: boolean;
    view: string;
    searchTerm: string;
    articles: IArticleSuggestion[];
    contents: IContentSuggestion[];
    featurePromotion: string;
    topSearchQueries: string[];
    priceMandantSettings: IPriceMandantSettings;
    customerType: string;
    priceL10n: IPriceL10n;
    seeAllResults: string;
    imageMainView: string;
    articlesSuggestHeadline: string;
    contentSuggestHeadline: string;
    topSearchQueriesHeadline: string;
    productFinderL10n: IProductFinderL10n;
    variantsL10n: IVariantsL10n;
    searchTarget?: string;
}

export interface ISuggestionsState {
    isHidden: boolean;
}

export interface ISuggestionsWithImagesAndPricesState {
    isHidden: boolean;
    isMergedColumnHidden: boolean;
    isMultipleColumnsHidden: boolean;
}

export interface IMappedSuggestionColumns {
    columnEntries: number;
    mergedColumn: IMappedSuggestions;
    multipleColumns: IMappedSuggestions;
}

export interface IMappedSuggestions {
    articles: IMappedArticle[];
    contentLinks: IMappedContent[];
    featurePromotion: IPromotion | null;
    topSearchQueries: ITopSearchQuery[];
}

export interface ISuggestions {
    type: string;
    clientSuggestion: ISuggestion[];
}

export interface ISuggestion {
    value: string;
    target: string;
    shortLink: string;
    disableHighlighting: boolean;
    type: string;
}

export interface IMappedSuggestion {
    isHeadline: boolean;
    value: string;
    target: string;
    shortLink: string;
    disableHighlighting: boolean;
    type: string;
}

export interface ISuggestionsWithImagesAndPrices {
    articles: IArticleSuggestion[];
    contents: IContentSuggestion[];
    featurePromotion: string;
    topSearchQueries: string[];
    priceMandantSettings: IPriceMandantSettings;
}

export interface IArticleSuggestion {
    title: string;
    image: string;
    price: IMinimalPriceContainer;
    url: string;
    shortLink: string;
    availableColorsCount: number;
    availableModelsCount: number;
}

export interface IMappedArticle {
    isHeadline: boolean;
    title: string;
    image: string;
    price?: IMinimalPriceContainer;
    target: string;
    shortLink: string;
    disableHighlighting: boolean;
    type: string;
    navIndex: number;
    availableColorsCount?: number;
    availableModelsCount?: number;
}

export interface IContentSuggestion {
    title: string;
    url: string;
    shortLink: string;
}

export interface IMappedContent {
    isHeadline: boolean;
    title: string;
    target: string;
    shortLink: string;
    disableHighlighting: boolean;
    type: string;
    navIndex: number;
}

export enum DeviceScrollClass {
    Desktop = "desktop-scroll",
    Kiosk = "kiosk-scroll",
    Tablet = "tablet-scroll",
    None = "no-scroll"
}
