/* eslint @typescript-eslint/no-explicit-any: 0 */
import * as React from "react";
import Suggestions from "../components/suggestions/suggestions";
import SuggestionsWithImagesAndPrices from "../components/suggestions/suggestionsWithImagesAndPrices";
import * as PageViewLogDataIdTypes from "../types/pageviewlogdataid";
import PageViewLogDataId from "./pageviewlogdataid/pageviewlogdataid";

export default class Search<P, S> extends React.Component<any, Record<string, any>> {
    _props: P;
    _state: S;

    readonly pageViewLogDataId: PageViewLogDataIdTypes.IPageViewLogDataId;

    searchBoxRef = React.createRef<HTMLDivElement>();
    inputRef = React.createRef<HTMLInputElement>();
    suggestionRef = React.createRef<Suggestions>();
    suggestionsWithImagesAndPricesRef = React.createRef<SuggestionsWithImagesAndPrices>();
    isDesktop = false;
    debouncedFetchSuggestionsFunc: () => void;

    constructor(_props: any) {
        super(_props);

        if (
            typeof window !== "undefined" &&
            document.documentElement.classList.contains("desktop")
        ) {
            this.isDesktop = true;
        }

        this.pageViewLogDataId = PageViewLogDataId.getInstance();
    }
}
