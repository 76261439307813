import React from "react";

const SVG = () => (
    <svg viewBox="0 0 23.7 24" className="search-2021-icon">
        <g data-name="Suche Step 2">
            <path
                d="M11,6.8A3,3,0,0,0,7,5.18a.72.72,0,0,1-1-.39.73.73,0,0,1,.39-1,4.48,4.48,0,0,1,5.87,
                            2.4.72.72,0,0,1-.39,1A.81.81,0,0,1,11,6.8Z"
            />
            <path
                d="M21.17,24a2.84,2.84,0,0,1-2-.84l-8.51-8.51A.94.94,0,0,1,12,13.31l8.51,8.51a.83.83,0,0,
                          0,1.15.12.69.69,0,0,0,.18-.51,1,1,0,0,0-.3-.64L13,12.28A.94.94,0,0,1,14.34,11l8.51,8.51a2.81,
                          2.81,0,0,1,.85,1.89A2.56,2.56,0,0,1,23,23.27,2.52,2.52,0,0,1,21.17,24Z"
            />
            <path
                d="M8.17,16.33a8.17,8.17,0,1,1,8.16-8.16c0,.31,0,.62,0,.93a.94.94,0,1,1-1.87-.21c0-.24,
                            0-.48,0-.72a6.29,6.29,0,1,0-6.28,6.28h.24a.95.95,0,0,1,.06,1.89Z"
            />
        </g>
    </svg>
);

export default SVG;
