import * as React from "react";
import * as SuggestionsTypes from "../../types/suggestions";
import * as LocaHelper from "../../util/localizationHelper";

import "./suggestions.scss";
import SuggestionListWithImagesAndPrices from "../suggestionlist/suggestionListWithImagesAndPrices";

import { IPromotion } from "../../types/promotion";
import { ITopSearchQuery } from "../../types/topsearchquery";

export default class ISuggestionsWithImagesAndPricesProps extends React.Component<
    SuggestionsTypes.ISuggestionsWithImagesAndPricesProps,
    SuggestionsTypes.ISuggestionsWithImagesAndPricesState
> {
    private readonly promotions: Record<string, IPromotion> =
        LocaHelper.mapProductFinderL10nToPromotion(this.props.productFinderL10n);

    private deviceScrollClass = SuggestionsTypes.DeviceScrollClass.None;
    private observer: ResizeObserver | null = null;
    private observedDocRef: HTMLElement | null = null;
    public mergedColumnRef = React.createRef<SuggestionListWithImagesAndPrices>();
    public multipleColumnsRef = React.createRef<SuggestionListWithImagesAndPrices>();

    constructor(props) {
        super(props);
        this.state = {
            isHidden: false,
            isMergedColumnHidden: true,
            isMultipleColumnsHidden: false
        };
    }

    public componentDidMount(): void {
        if (this.props.isWide) {
            this.enableTouchScrollOnWideSuggestionsForTabletAndKiosk();
        }
        if (!this.props.isWide) {
            if (this.props.view === "flyout") this.setFlyoutVisibilty(window.innerWidth);
            if (this.props.view === "grid") this.setGridVisibilty(window.innerWidth);
            const docElement = document.documentElement;
            if (docElement) {
                this.observedDocRef = docElement;
                this.setupResizeObserver();
            }
        }
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.setState({ isHidden: false });
        }
    }

    public componentWillUnmount(): void {
        if (this.observer) this.observer.disconnect();
    }

    public render() {
        this.enableScrollOnWideSuggestionsForDevices();
        const mappedSuggestions = this.mapSuggestionsToColumns();

        return (
            <>
                <div
                    className={
                        "suggestions-container multiple-columns" +
                        `${this.isColumnHidden(false)}` +
                        `${this.getDeviceScrollClass()}`
                    }
                >
                    <SuggestionListWithImagesAndPrices
                        ref={this.multipleColumnsRef}
                        mappedArticles={mappedSuggestions.multipleColumns.articles}
                        mappedContentLinks={mappedSuggestions.multipleColumns.contentLinks}
                        mappedFeaturePromotion={mappedSuggestions.multipleColumns.featurePromotion}
                        mappedTopSearchQueries={mappedSuggestions.multipleColumns.topSearchQueries}
                        priceMandantSettings={this.props.priceMandantSettings}
                        isMergedList={false}
                        navListLength={mappedSuggestions.columnEntries}
                        trackingEndpoint={this.props.trackingEndpoint}
                        portal={this.props.portal}
                        culture={this.props.culture}
                        searchTerm={this.props.searchTerm}
                        view={this.props.view}
                        customerType={this.props.customerType}
                        priceL10n={this.props.priceL10n}
                        variantsL10n={this.props.variantsL10n}
                        imageMainView={this.props.imageMainView}
                    />
                </div>

                {!this.props.isWide && (
                    <div
                        className={
                            "suggestions-container merged-column" + `${this.isColumnHidden(true)}`
                        }
                    >
                        <SuggestionListWithImagesAndPrices
                            ref={this.mergedColumnRef}
                            mappedArticles={mappedSuggestions.mergedColumn.articles}
                            mappedContentLinks={mappedSuggestions.mergedColumn.contentLinks}
                            mappedFeaturePromotion={mappedSuggestions.mergedColumn.featurePromotion}
                            mappedTopSearchQueries={mappedSuggestions.mergedColumn.topSearchQueries}
                            priceMandantSettings={this.props.priceMandantSettings}
                            isMergedList={true}
                            navListLength={mappedSuggestions.columnEntries}
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            searchTerm={this.props.searchTerm}
                            view={this.props.view}
                            customerType={this.props.customerType}
                            priceL10n={this.props.priceL10n}
                            variantsL10n={this.props.variantsL10n}
                            imageMainView={this.props.imageMainView}
                        />
                    </div>
                )}
            </>
        );
    }

    private isDesktop(): boolean {
        const device = this.props.portal.split(".")[0].toLowerCase();
        return device === "portal";
    }

    private getDeviceScrollClass(): string {
        return " " + this.deviceScrollClass;
    }

    private enableScrollOnWideSuggestionsForDevices(): void {
        const isKiosk = document.documentElement.classList.contains("kiosk");
        const isTablet = document.documentElement.classList.contains("tablet");

        if (this.props.isWide && isKiosk) {
            this.deviceScrollClass = SuggestionsTypes.DeviceScrollClass.Kiosk;
        } else if (this.props.isWide && isTablet) {
            this.deviceScrollClass = SuggestionsTypes.DeviceScrollClass.Tablet;
        } else if (this.props.isWide && this.isDesktop()) {
            this.enableScrollForDesktop();
        }
    }

    private enableScrollForDesktop(): void {
        // enable scrolling of left suggestions on desktop devices with narrow screen height
        const innerHeight = window.innerHeight;
        const mktHeaderHeight = 50;
        const leftSuggestionContentMaxHeight = 918;
        const windowMinHeight = mktHeaderHeight + leftSuggestionContentMaxHeight;
        if (innerHeight - windowMinHeight < 0) {
            this.deviceScrollClass = SuggestionsTypes.DeviceScrollClass.Desktop;
        }
    }

    private enableTouchScrollOnWideSuggestionsForTabletAndKiosk(): void {
        const isKiosk = document.documentElement.classList.contains("kiosk");
        const isTablet = document.documentElement.classList.contains("tablet");
        const suggestionsWideBox = document.getElementById("new-suggestions-wide");

        // enables touch scroll on parallax pages
        if (suggestionsWideBox && (isKiosk || isTablet)) {
            suggestionsWideBox.ontouchstart = (e) => e.stopPropagation();
            suggestionsWideBox.ontouchend = (e) => e.stopPropagation();
            suggestionsWideBox.ontouchmove = (e) => e.stopPropagation();
        }
    }

    private isColumnHidden(isMergedColumn: boolean): string {
        if (isMergedColumn) {
            return this.state.isMergedColumnHidden ? " hidden" : "";
        } else {
            return this.props.isWide || !this.state.isMultipleColumnsHidden ? "" : " hidden";
        }
    }

    private setupResizeObserver(): void {
        this.observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                if (width > 0 && height > 0) {
                    if (this.props.view === "flyout") this.setFlyoutVisibilty(width);
                    if (this.props.view === "grid") this.setGridVisibilty(width);
                }
            });
        });

        this.observer.observe(this.observedDocRef);
    }

    private setFlyoutVisibilty(width: number): void {
        const isMobileshop = document.documentElement.classList.contains("mobileshop");
        const maxWidthForMergedColumn = 767;
        const maxWidthForMultipleColumns = 991;

        if (isMobileshop || (width > 0 && width <= maxWidthForMergedColumn)) {
            this.setState({ isMergedColumnHidden: false, isMultipleColumnsHidden: true });
        } else if (width > maxWidthForMergedColumn && width <= maxWidthForMultipleColumns) {
            this.setState({ isMergedColumnHidden: true, isMultipleColumnsHidden: false });
        }
    }

    private setGridVisibilty(width: number): void {
        const maxWidthForMergedColumn = 680;

        if (width <= maxWidthForMergedColumn) {
            this.setState({ isMergedColumnHidden: false, isMultipleColumnsHidden: true });
        } else if (width > maxWidthForMergedColumn) {
            this.setState({ isMergedColumnHidden: true, isMultipleColumnsHidden: false });
        }
    }

    private mapSuggestionsToColumns(): SuggestionsTypes.IMappedSuggestionColumns {
        let mergedColumnLength = 0;
        let multipleColumnsLength = 0;
        const mappedSuggestions: SuggestionsTypes.IMappedSuggestionColumns = {
            columnEntries: 0,
            mergedColumn: {
                articles: [],
                contentLinks: [],
                featurePromotion: null,
                topSearchQueries: []
            },
            multipleColumns: {
                articles: [],
                contentLinks: [],
                featurePromotion: null,
                topSearchQueries: []
            }
        };

        const promotion = this.getPromotion(this.props.featurePromotion);
        if (promotion) {
            mappedSuggestions.mergedColumn.featurePromotion = promotion;
            mappedSuggestions.multipleColumns.featurePromotion = promotion;
            mergedColumnLength++;
            multipleColumnsLength++;
        }

        const topSearchQueries = this.mapTopSearchQueries(
            this.props.topSearchQueries,
            mergedColumnLength
        );
        mappedSuggestions.mergedColumn.topSearchQueries = topSearchQueries;
        mappedSuggestions.multipleColumns.topSearchQueries = topSearchQueries;
        mergedColumnLength += mappedSuggestions.mergedColumn.topSearchQueries.length;
        multipleColumnsLength += mappedSuggestions.multipleColumns.topSearchQueries.length;

        const articlesMergedColumn = this.mapArticles(this.props.articles, mergedColumnLength);
        mappedSuggestions.mergedColumn.articles = articlesMergedColumn;
        mergedColumnLength += mappedSuggestions.mergedColumn.articles.length;

        const contentMergedColumn = this.mapContentLinks(this.props.contents, mergedColumnLength);
        mappedSuggestions.mergedColumn.contentLinks = contentMergedColumn;
        mergedColumnLength += mappedSuggestions.mergedColumn.contentLinks.length;

        const contentMultipleColumns = this.mapContentLinks(
            this.props.contents,
            multipleColumnsLength
        );
        mappedSuggestions.multipleColumns.contentLinks = contentMultipleColumns;
        multipleColumnsLength += mappedSuggestions.multipleColumns.contentLinks.length;

        const articlesMultipleColumns = this.mapArticles(
            this.props.articles,
            multipleColumnsLength
        );
        mappedSuggestions.multipleColumns.articles = articlesMultipleColumns;
        multipleColumnsLength += mappedSuggestions.multipleColumns.articles.length;

        mappedSuggestions.columnEntries = mergedColumnLength;
        return mappedSuggestions;
    }

    private getPromotion(featurePromotionName: string | null): IPromotion | null {
        if (!this.isDesktop() || featurePromotionName === null || featurePromotionName === "") {
            return null;
        }

        const normalizedFeaturePromotionName = featurePromotionName.toLowerCase();

        return this.promotions[normalizedFeaturePromotionName] || null;
    }

    private mapTopSearchQueries(
        topSearchQueries: string[],
        columnLength: number
    ): ITopSearchQuery[] {
        if (topSearchQueries.length === 0) return [];
        const result: ITopSearchQuery[] = [];

        result.push({
            value: this.props.topSearchQueriesHeadline,
            isHeadline: true,
            target: "",
            disableHighlighting: true,
            type: "TopSearchQuery",
            navIndex: columnLength++
        });

        topSearchQueries.forEach((query) => {
            const mappedTopSearchQuery: ITopSearchQuery = {
                value: query,
                isHeadline: false,
                target: `${this.props.searchTarget}?query=${encodeURIComponent(query)}`,
                disableHighlighting: false,
                type: "TopSearchQuery",
                navIndex: columnLength++
            };
            result.push(mappedTopSearchQuery);
        });

        return result;
    }

    private mapArticles(
        articles: SuggestionsTypes.IArticleSuggestion[],
        columnLength: number
    ): SuggestionsTypes.IMappedArticle[] {
        if (articles.length === 0) return [];
        const result: SuggestionsTypes.IMappedArticle[] = [];

        result.push({
            isHeadline: true,
            title: this.props.articlesSuggestHeadline,
            image: "",
            target: "",
            shortLink: "",
            disableHighlighting: true,
            type: "Articles",
            navIndex: columnLength++
        });

        articles.forEach((article) => {
            const mappedArticle: SuggestionsTypes.IMappedArticle = {
                isHeadline: false,
                title: article.title,
                image: article.image,
                price: article.price,
                target: article.url,
                shortLink: article.shortLink,
                disableHighlighting: false,
                type: "Articles",
                navIndex: columnLength++,
                availableColorsCount: article.availableColorsCount,
                availableModelsCount: article.availableModelsCount
            };
            result.push(mappedArticle);
        });

        if (result.length > 2) {
            const searchQuery = `${this.props.searchTarget}?query=${encodeURIComponent(
                this.props.searchTerm
            )}`;

            result.push({
                isHeadline: false,
                title: this.props.seeAllResults,
                image: "",
                target: searchQuery,
                shortLink: "",
                disableHighlighting: true,
                type: "ShowAllResults",
                navIndex: columnLength++
            });
        }

        return result;
    }

    private mapContentLinks(
        contentLinks: SuggestionsTypes.IContentSuggestion[],
        columnLength: number
    ): SuggestionsTypes.IMappedContent[] {
        if (contentLinks.length === 0) return [];
        const result: SuggestionsTypes.IMappedContent[] = [];

        result.push({
            isHeadline: true,
            title: this.props.contentSuggestHeadline,
            target: "",
            shortLink: "",
            disableHighlighting: true,
            type: "content",
            navIndex: columnLength++
        });

        contentLinks.forEach((content) => {
            const mappedContent: SuggestionsTypes.IMappedContent = {
                isHeadline: false,
                title: content.title,
                target: content.url,
                shortLink: content.shortLink,
                disableHighlighting: false,
                type: "content",
                navIndex: columnLength++
            };
            result.push(mappedContent);
        });

        return result;
    }

    public selectNextSuggestionEntry(isOpposite: boolean): void {
        this.setState({ isHidden: false });
        this.state.isMultipleColumnsHidden
            ? this.mergedColumnRef.current.selectNextListSuggestionEntry(isOpposite)
            : this.multipleColumnsRef.current.selectNextListSuggestionEntry(isOpposite);
    }

    public triggerSuggestionRedirect(): boolean {
        if (this.state.isMultipleColumnsHidden) {
            if (this.mergedColumnRef.current?.selectedNavIndex != null) {
                this.mergedColumnRef.current.triggerListSuggestionRedirect();
            } else return false;
        } else {
            if (this.multipleColumnsRef.current?.selectedNavIndex != null) {
                this.multipleColumnsRef.current.triggerListSuggestionRedirect();
            } else return false;
        }
        return true;
    }
}
