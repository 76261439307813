import { IPriceL10n } from "../types/article-price";
import { IPromotion } from "../types/promotion";
import { IProductFinderL10n, IVariantsL10n } from "../types/searchbox";

export function decodeHTML(value: string): string {
    return value.replace(/&#(\d+);/g, (_match, dec) => {
        return String.fromCharCode(dec);
    });
}

export function decodePriceL10n(prices: IPriceL10n): IPriceL10n {
    const priceL10n: IPriceL10n = {
        colon: decodeHTML(prices.colon),
        comparisonPrice: decodeHTML(prices.comparisonPrice),
        exVATAcronym: decodeHTML(prices.exVATAcronym),
        incVATAcronym: decodeHTML(prices.incVATAcronym),
        priceFromLocale: decodeHTML(prices.priceFromLocale)
    };
    return priceL10n;
}

export function decodeVariantsL10n(variants: IVariantsL10n): IVariantsL10n {
    const variantsL10n: IVariantsL10n = {
        color: decodeHTML(variants.color),
        colors: decodeHTML(variants.colors),
        variant: decodeHTML(variants.variant),
        variants: decodeHTML(variants.variants)
    };
    return variantsL10n;
}

export function decodeProductFinderL10n(finders: IProductFinderL10n): IProductFinderL10n {
    const productFinderL10n: IProductFinderL10n = {
        trouserFinderHeadline: decodeHTML(finders.trouserFinderHeadline),
        trouserFinderCTA: decodeHTML(finders.trouserFinderCTA),
        trouserFinderLink: decodeHTML(finders.trouserFinderLink),
        trouserFinderShortLink: decodeHTML(finders.trouserFinderShortLink),
        trouserFinderLogo: decodeHTML(finders.trouserFinderLogo),
        jacketFinderCTA: decodeHTML(finders.jacketFinderCTA),
        jacketFinderHeadline: decodeHTML(finders.jacketFinderHeadline),
        jacketFinderLink: decodeHTML(finders.jacketFinderLink),
        jacketFinderShortLink: decodeHTML(finders.jacketFinderShortLink),
        jacketFinderLogo: decodeHTML(finders.jacketFinderLogo),
        shoeFinderCTA: decodeHTML(finders.shoeFinderCTA),
        shoeFinderHeadline: decodeHTML(finders.shoeFinderHeadline),
        shoeFinderLink: decodeHTML(finders.shoeFinderLink),
        shoeFinderShortLink: decodeHTML(finders.shoeFinderShortLink),
        shoeFinderLogo: decodeHTML(finders.shoeFinderLogo),
        productFinderLogoBasePathPreview: decodeHTML(finders.productFinderLogoBasePathPreview),
        productFinderLogoBasePathRelease: decodeHTML(finders.productFinderLogoBasePathRelease)
    };
    return productFinderL10n;
}

export function mapProductFinderL10nToPromotion(
    productFinderL10n: IProductFinderL10n
): Record<string, IPromotion> {
    return {
        trouserfinder: {
            name: "TrouserFinder",
            logoUrl: productFinderL10n.trouserFinderLogo,
            headline: productFinderL10n.trouserFinderHeadline,
            cta: productFinderL10n.trouserFinderCTA,
            link: productFinderL10n.trouserFinderLink,
            shortLink: productFinderL10n.trouserFinderShortLink,
            productFinderLogoBasePathPreview: productFinderL10n.productFinderLogoBasePathPreview,
            productFinderLogoBasePathRelease: productFinderL10n.productFinderLogoBasePathRelease,
            navIndex: 0
        },
        jacketfinder: {
            name: "JacketFinder",
            logoUrl: productFinderL10n.jacketFinderLogo,
            headline: productFinderL10n.jacketFinderHeadline,
            cta: productFinderL10n.jacketFinderCTA,
            link: productFinderL10n.jacketFinderLink,
            shortLink: productFinderL10n.jacketFinderShortLink,
            productFinderLogoBasePathPreview: productFinderL10n.productFinderLogoBasePathPreview,
            productFinderLogoBasePathRelease: productFinderL10n.productFinderLogoBasePathRelease,
            navIndex: 0
        },
        shoefinder: {
            name: "ShoeFinder",
            logoUrl: productFinderL10n.shoeFinderLogo,
            headline: productFinderL10n.shoeFinderHeadline,
            cta: productFinderL10n.shoeFinderCTA,
            link: productFinderL10n.shoeFinderLink,
            shortLink: productFinderL10n.shoeFinderShortLink,
            productFinderLogoBasePathPreview: productFinderL10n.productFinderLogoBasePathPreview,
            productFinderLogoBasePathRelease: productFinderL10n.productFinderLogoBasePathRelease,
            navIndex: 0
        }
    };
}
