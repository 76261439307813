import * as React from "react";
import * as SuggestionsTypes from "../../types/suggestions";
import * as PageViewLogDataIdTypes from "../../types/pageviewlogdataid";

import { IPromotion } from "../../types/promotion";
import { ISuggestionsListProps } from "../../types/suggestionlist";
import { ITopSearchQuery } from "../../types/topsearchquery";

import PageViewLogDataId from "../../util/pageviewlogdataid/pageviewlogdataid";
import Promotion from "../promotion/promotion";

export default class SuggestionList extends React.Component<ISuggestionsListProps> {
    public currentSelectedIndex: number = null;
    public lastListIndex: number = null;
    private readonly pageViewLogDataId: PageViewLogDataIdTypes.IPageViewLogDataId;
    private refArray: HTMLElement[] = [];

    constructor(props) {
        super(props);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.pageViewLogDataId = PageViewLogDataId.getInstance();
    }

    public render() {
        const showPromotion = this.props.mappedFeaturePromotion !== null;
        const showStandardSuggestions = this.props.mappedSuggestions.length > 0;
        const showTopSearchQueries = this.props.mappedTopSearchQueries.length > 0;

        this.setLastSuggestionIndices(showStandardSuggestions);

        return (
            <ul className={this.getSuggestionListClass()}>
                {showPromotion ? (
                    <Promotion
                        promotion={this.props.mappedFeaturePromotion}
                        onClick={this.handlePromotionClick.bind(
                            this,
                            this.props.mappedFeaturePromotion
                        )}
                        style=""
                    />
                ) : null}
                {showTopSearchQueries
                    ? this.props.mappedTopSearchQueries.map((query, index) =>
                          query.isHeadline ? (
                              <li
                                  className={"top-search-query-text"}
                                  key={index}
                                  ref={this.bindRef.bind(this, index)}
                              >
                                  {query.value}
                              </li>
                          ) : (
                              <li
                                  className={"top-search-query-item"}
                                  key={index}
                                  ref={this.bindRef.bind(this, index)}
                                  onMouseEnter={this.handleMouseEnter.bind(this, index)}
                                  onMouseLeave={this.handleMouseLeave}
                              >
                                  <a
                                      href={query.target}
                                      onClick={this.handleTopSearchItemClick.bind(
                                          this,
                                          query,
                                          index
                                      )}
                                      dangerouslySetInnerHTML={{
                                          __html: this.highlightText(
                                              query.value,
                                              this.props.searchTerm
                                          )
                                      }}
                                      data-testid={`ssw-top-search-${index}`}
                                  />
                              </li>
                          )
                      )
                    : null}
                {showStandardSuggestions
                    ? this.props.mappedSuggestions.map((suggestion, index) =>
                          suggestion.isHeadline ? (
                              <li
                                  className={`suggestions-text ${suggestion.type}`}
                                  key={this.getListIndex(index)}
                                  ref={this.bindRef.bind(this, this.getListIndex(index))}
                              >
                                  {suggestion.value}
                              </li>
                          ) : (
                              <li
                                  className={`suggestion-item ${suggestion.type}`}
                                  key={this.getListIndex(index)}
                                  ref={this.bindRef.bind(this, this.getListIndex(index))}
                                  onMouseEnter={this.handleMouseEnter.bind(
                                      this,
                                      this.getListIndex(index)
                                  )}
                                  onMouseLeave={this.handleMouseLeave}
                              >
                                  <a
                                      href={suggestion.target}
                                      onClick={this.handleClick.bind(
                                          this,
                                          suggestion,
                                          this.getListIndex(index)
                                      )}
                                      dangerouslySetInnerHTML={{
                                          __html: suggestion.disableHighlighting
                                              ? suggestion.value
                                              : this.highlightText(
                                                    suggestion.value,
                                                    this.props.searchTerm
                                                )
                                      }}
                                      data-testid={`ssw-suggested-${suggestion.type}-${index}`}
                                  />
                              </li>
                          )
                      )
                    : null}
            </ul>
        );
    }

    private getListIndex(index: number): number {
        const sharedIndex = this.props.firstListLength > 0 ? this.props.firstListLength - 1 : 0;
        return this.props.isLeftList ? index : sharedIndex + index + 1;
    }

    private setLastSuggestionIndices(showStandardSuggestions: boolean): void {
        if (this.props.isSingleList) {
            this.lastListIndex = showStandardSuggestions
                ? this.props.mappedSuggestions.length - 1
                : this.props.mappedTopSearchQueries.length - 1;
        } else {
            const maxLength = this.props.mappedSuggestions.length + this.props.firstListLength;
            this.lastListIndex = this.props.isLeftList
                ? this.props.mappedTopSearchQueries.length - 1
                : maxLength - 1;
        }
    }

    private getSuggestionListClass(): string {
        if (this.props.isSingleList) return "";
        return this.props.isLeftList ? "left" : "right";
    }

    private handleClick(
        suggestion: SuggestionsTypes.ISuggestion,
        index: number,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.handleTouched.bind(this, index);

        this.recordSuggestionUsed(suggestion);

        window.location.href = suggestion.target;
    }

    private handlePromotionClick(
        promotion: IPromotion,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.recordFeaturePromotionUsed(promotion);

        window.location.href = promotion.link;
    }

    private handleTopSearchItemClick(
        query: ITopSearchQuery,
        index: number,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.handleTouched.bind(this, index);

        this.recordTopSearchQueryUsed(query);

        window.location.href = query.target;
    }

    private recordSuggestionUsed(suggestion: SuggestionsTypes.ISuggestion): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: suggestion.shortLink,
            suggestion: suggestion.value,
            contentType: null
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordFeaturePromotionUsed(promotion: IPromotion): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: promotion.shortLink,
            suggestion: promotion.name,
            contentType: "FeaturePromotion"
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordTopSearchQueryUsed(query: ITopSearchQuery): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: "",
            suggestion: query.value,
            contentType: query.type
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private sendTelemetryData(jsonData: string): void {
        if (
            window &&
            window.navigator &&
            typeof window.navigator.sendBeacon === "function" &&
            typeof window.Blob === "function"
        ) {
            const blobData = new window.Blob([jsonData], {
                type: "application/json"
            });

            try {
                if (window.navigator.sendBeacon(this.props.trackingEndpoint, blobData)) return;
            } catch (e) {
                // fallback below
            }
        }

        setTimeout(() => {
            fetch(this.props.trackingEndpoint, {
                body: jsonData,
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
        }, 0);
    }

    private bindRef(index: number, element): void {
        this.refArray[index] = element;
    }

    public selectNextSuggestionEntry(isOpposite: boolean): void {
        const selectedIndex: number = this.currentSelectedIndex;
        this.deselectEntry();

        const startIndex = this.props.isLeftList ? 0 : this.props.firstListLength;

        let indexToSelect: number = null;
        if (selectedIndex != null) {
            indexToSelect = isOpposite ? selectedIndex - 1 : selectedIndex + 1;
        } else {
            indexToSelect = isOpposite ? this.lastListIndex : startIndex;
        }

        if (indexToSelect < 0) {
            indexToSelect = this.props.isSingleList ? this.lastListIndex : null;
        } else if (indexToSelect > this.lastListIndex) {
            indexToSelect = this.props.isSingleList ? 0 : null;
        }

        if (indexToSelect != null) {
            this.assignSelectedSuggestionEntry(indexToSelect, isOpposite);
        } else {
            this.currentSelectedIndex = indexToSelect;
        }
    }

    private assignSelectedSuggestionEntry(indexToSelect: number, isOpposite: boolean): void {
        this.selectEntry(indexToSelect);

        // skip headlines
        const entryIsHeadline: boolean =
            this.refArray[indexToSelect].classList.contains("suggestions-text") ||
            this.refArray[indexToSelect].classList.contains("top-search-query-text");
        if (entryIsHeadline) this.selectNextSuggestionEntry(isOpposite);
    }

    public triggerSuggestionRedirect(): void {
        this.refArray[this.currentSelectedIndex].getElementsByTagName("a")[0].click();
    }

    private selectEntry(index: number): void {
        this.currentSelectedIndex = index;
        this.refArray[index].classList.add("is-selected");
    }

    public deselectEntry(): void {
        if (this.currentSelectedIndex != null) {
            this.refArray[this.currentSelectedIndex].classList.remove("is-selected");
            this.currentSelectedIndex = null;
        }
    }

    private handleMouseEnter(index: number): void {
        this.deselectEntry();
        this.selectEntry(index);
    }

    private handleMouseLeave(): void {
        this.deselectEntry();
    }

    private handleTouched(index: number): void {
        this.refArray.forEach((element) => {
            if (element) element.classList.remove("is-touched");
        });

        this.refArray[index].classList.add("is-touched");
    }

    private highlightText(suggestion: string, searchInput: string): string {
        const inputTerms: string = searchInput
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            .split(" ")
            .filter((n) => n)
            .join("|");

        const regex = RegExp(inputTerms, "gi");
        return suggestion.replace(regex, "<strong>$&</strong>");
    }
}
